import React from 'react'
import '../markdown.css'
import { graphql } from 'gatsby'

export default props => {
    const {
        data: {
            markdownRemark: { frontmatter, html },
        },
    } = props

    return (
        <div className='prose prose-lg mx-auto pb-12'>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    )
}

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: { title: { eq: "Terms of Service" } }) {
            html
            frontmatter {
                title
            }
        }
    }
`
